import {FloorItem, FloorItemProxy} from '@/pages/floor/renovation/services/floor';
import {GoodsProxy} from '@/pages/floor/renovation/services/goods';

export class FloorItem6Proxy extends FloorItemProxy {
  goodsList!: GoodsProxy[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.goodsList = block.block_value.reduce((map, goods) => {
        if (!map[goods.goods_id]) {
          map[goods.goods_id] = true;
          map.array.push(GoodsProxy.create(goods));
        }
        return map;
      }, {array: []}).array;
    } else {
      this.goodsList = [];
    }
  }

  hasError() {
    return false;
  }

  toJson() {
    return [
      {
        block_type: 'GOODSLIST',
        block_value: this.goodsList.map(g => g.toJson()),
      }
    ];
  }
}
