
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { Subject } from 'rxjs'
import draggable from 'vuedraggable'
import GoodsCard from '../goods-card/goods-card.vue'
import { $goods, GoodsProxy } from '@/pages/floor/renovation/services/goods'
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue'
import { getGoodsInfo } from '../../../api'

@Component({
  name: 'goods-editor',
  components: {
    FloorEditorFrame,
    GoodsCard,
    draggable
  }
})
export default class GoodsEditor extends Vue {
  @PropSync('title', { default: '' }) realTitle!: string
  @PropSync('disableAction', { default: false }) realDisableAction!: boolean
  @PropSync('onSave') realOnSave!: Subject<any>
  @PropSync('onReset') realOnReset!: Subject<any>
  @PropSync('onRemove') realOnRemove!: Subject<any>

  @PropSync('goodsList', {
    required: true,
    default: () => []
  }) realGoodsList!: GoodsProxy[]

  sorts = [
    {
      icon: 'el-icon-sort-up',
      label: '升序',
      command: 'up'
    },
    {
      icon: 'el-icon-sort-down',
      label: '降序',
      command: 'down'
    },
    {
      icon: 'el-icon-sort',
      label: '无序',
      command: '',
      hidden: true
    }
  ]

  page_no = 0
  page_size = 20
  goodsEditorList: any = []
  shopInfo2021: any = sessionStorage.getItem('shopInfo2021')
  mounted() {
    getGoodsInfo({
      shop_id: JSON.parse(this.shopInfo2021).shop_id,
      good_ids: this.realGoodsList
        .map(g => {
          return g.id
        })
        .join(',')
    }).then(res => {
      this.goodsEditorList = res.map(g => GoodsProxy.create(g))
      this.realGoodsList = res.map(g => GoodsProxy.create(g))
    })
  }

  getGoodsList: any = []

  currentSort = this.sorts[2]
  selectSort(sort = this.sorts[2]) {
    this.currentSort = sort

    let sortFn: (g1: GoodsProxy, g2: GoodsProxy) => number = null!

    if (sort.command === 'up') {
      sortFn = (g1, g2) => g1.marketPrice - g2.marketPrice
    } else if (sort.command === 'down') {
      sortFn = (g1, g2) => g2.marketPrice - g1.marketPrice
    }

    if (sortFn) {
      this.goodsEditorList = this.goodsEditorList.sort(sortFn)
      this.realGoodsList = this.realGoodsList.sort(sortFn)
    }

    this.$emit('sync')
  }

  goodsResorted() {
    this.currentSort = this.sorts[2]
    this.$emit('sync')
  }

  selectGoods() {
    console.log(this.goodsEditorList)
    $goods.selectGoods(true, [...this.goodsEditorList].reverse()).then(resp => {
      console.log(resp, 'resp')
      this.realGoodsList = resp.reverse()
      this.goodsEditorList = resp.reverse()
      this.$emit('sync')
    })
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return

    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.unshift(goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.splice(index - 1, 0, goods)
    this.goodsEditorList = list
    this.realGoodsList = list

    this.$emit('sync')
  }

  removeGoods(index) {
    this.goodsEditorList.splice(index, 1)
    this.realGoodsList.splice(index, 1)
    this.$emit('sync')
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.splice(index + 1, 0, goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.push(goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }
}
