
import { Component, Mixins } from 'vue-property-decorator'
import { TemplateExtra } from '../module'
import { GoodsProxy } from '@/pages/floor/renovation/services/goods'
import GoodsCard from '../$components/goods-card/goods-card.vue'
import Placeholder from './placeholder.vue'
import { getGoodsInfo } from '../../api'

@Component({
  name: 'template-6',
  components: {
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = []
  frontData: GoodsProxy[] = []
  frontPageSize = 20
  frontPageIndex = 0
  frontHasNextPage = true

  init() {
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || []

      const first = list[0]
      if (first?.block_type === 'GOODSLIST') {
        list = first.block_value.reduce(
          (map, goods) => {
            if (!map[goods.goods_id]) {
              map[goods.goods_id] = true
              map.array.push(goods)
            }
            return map
          },
          { array: [] }
        ).array
      }

      this.goodsList = list.map(g => GoodsProxy.create(g))
      this.resetPage()
      this.loadNextPage()
    })
  }

  resetPage() {
    this.frontHasNextPage = true
    this.frontPageIndex = 0
    this.frontData = []
  }

  loadNextPage() {
    if (!this.frontHasNextPage) return
    if (this.goodsList.length === 0) return

    const startIndex = this.frontPageIndex * this.frontPageSize
    let endIndex = (this.frontPageIndex + 1) * this.frontPageSize
    if (endIndex > this.goodsList.length) {
      endIndex = this.goodsList.length
      this.frontHasNextPage = false
    }
    const shopInfo2021: any = sessionStorage.getItem('shopInfo2021')
    getGoodsInfo({
      shop_id: JSON.parse(shopInfo2021).shop_id,
      good_ids: this.goodsList.slice(startIndex, endIndex)
        .map(g => {
          return g.originalData.goods_id
        })
        .join(',')
    }).then(res => {
      let tmp = []
      if (res) tmp = res.map(g => GoodsProxy.create(g))
      this.frontData = [
        ...this.frontData,
        ...tmp
      ]
      this.frontPageIndex++
    })
  }
}
