
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import ElevatorAddGoods from './elevator-add-goods.vue'
import { Floor } from '@/pages/floor/renovation/services/floor'
import { GoodsProxy } from '@/pages/floor/renovation/services/goods'
import { getGoodsInfo } from '../../../api'

@Component({
  name: 'elevator-add-floor',
  components: { ElevatorAddGoods }
})
export default class ElevatorAddFloor extends Vue {
  @PropSync('subSwitch') realSubSwitch
  @PropSync('floorList') realFloorList
  @PropSync('floor') realFloor!: Floor

  dialogVisible = false
  selectFloor = 0
  goodsList: any = []
  clickOne = false
  dialogTitle = '添加楼层'

  titleStyle = {
    exceedStatus: false, // 楼层标题宽度之和是否超出容器宽度
    showLeftFloor: 0
  }

  ruleForm = {
    name: '',
    subName: '',
    goodsList: []
  }

  rules = {
    name: [
      { required: true, message: '请输入楼层名称', trigger: 'blur' },
      { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
    ],
    subName: [
      { required: true, message: '请输入楼层副名称', trigger: 'blur' },
      { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
    ]
  }

  shopInfo2021: any = sessionStorage.getItem('shopInfo2021')

  @Watch('realSubSwitch', {
    immediate: true,
    deep: true
  })
  subSwitchHandle() {
    this.getDomInfo()
  }

  @Watch('realFloor', { immediate: true })
  watchFloor(newSrc) {
    if (newSrc) {
      this.realFloor.elevatorSelectFloor.subscribe(value => {
        if (value >= this.realFloorList.length) return
        this.selectFloor = value
        const list = JSON.parse(
          JSON.stringify(this.realFloorList[value].goodsList)
        )
        this.getGoodsList(list)
      })
    }
  }

  addFloor() {
    this.dialogVisible = true
    this.dialogTitle = '添加楼层'
    if (!this.$refs.formItem) return
    const refs: any = this.$refs
    refs.formItem.resetField()
    refs.formItem02.resetField()
  }

  floorDbClick(name, subName) {
    this.dialogVisible = true
    this.dialogTitle = '编辑楼层'
    this.ruleForm.name = name
    this.ruleForm.subName = subName
  }

  /**
   * 处理表单数据
   * @param type 事件类型
   */
  handleFormData(type) {
    if (type === 'confirm') {
      if (this.clickOne) {
        this.$message.warning('请勿连续提交楼层')
        return
      }
      this.clickOne = true
      const refs: any = this.$refs
      refs['ruleForm'].validate(valid => {
        if (valid) {
          const value: any = JSON.parse(JSON.stringify(this.ruleForm))
          if (this.dialogTitle === '添加楼层') {
            this.realFloorList.push(value)
            this.selectFloorClick(this.realFloorList.length - 1)
          } else {
            value.goodsList = this.realFloorList[this.selectFloor].goodsList
            this.realFloorList[this.selectFloor] = value
          }
          this.getDomInfo()
          this.dialogVisible = false
        } else {
          return false
        }
      })
      setTimeout(() => {
        this.clickOne = false
      }, 1000)
    } else {
      this.dialogVisible = false
    }
  }

  selectFloorClick(index) {
    this.selectFloor = index
    const list = JSON.parse(JSON.stringify(this.realFloorList[index].goodsList))
    this.getGoodsList(list)
    this.realFloor.elevatorSelectFloor.next(index)
    console.log(this.goodsList, index)
  }

  handleSync() {
    this.realFloorList[this.selectFloor].goodsList = JSON.parse(
      JSON.stringify(this.goodsList)
    )
  }

  handleTagClose(index) {
    if (index === this.realFloorList.length - 1 && index !== 0) {
      this.selectFloor = index - 1
      const list = JSON.parse(
        JSON.stringify(this.realFloorList[index - 1].goodsList)
      )
      this.getGoodsList(list)
    } else if (index !== 0) {
      const list = JSON.parse(
        JSON.stringify(this.realFloorList[index + 1].goodsList)
      )
      this.getGoodsList(list)
    }

    this.realFloorList.splice(index, 1)
    this.getDomInfo()
  }

  floorTab(type) {
    const boxDom: any = document.querySelector('.floor-tag-box')
    const tagList = document.querySelectorAll('.elevator-el-tag')
    let wSum = 0
    tagList.forEach(value => {
      const data: any = value
      if (data.style.display !== 'none') {
        wSum += value.clientWidth
      }
    })
    if (type === 'left' && this.titleStyle.showLeftFloor !== 0) {
      this.titleStyle.showLeftFloor--
    } else if (type === 'right' && wSum > boxDom.clientWidth) {
      this.titleStyle.showLeftFloor++
    }
  }

  handleClose(done) {
    this.$confirm('楼层信息未保存，是否继续关闭？')
      .then(() => {
        done()
      })
      .catch(() => {
      })
  }

  /**
   * 获取楼层标签的Dom节点对象信息
   */
  getDomInfo() {
    this.$nextTick(() => {
      const boxDom: any = document.querySelector('.floor-tag-box')
      if (!boxDom) return
      setTimeout(() => {
        const tagList = document.querySelectorAll('.elevator-el-tag')
        let wTagSum = tagList.length + 1
        tagList.forEach(value => {
          wTagSum = wTagSum + value.clientWidth
        })
        this.titleStyle.exceedStatus = wTagSum > boxDom.clientWidth
      }, 500)
    })
  }

  getGoodsList(list) {
    if (list.length) {
      getGoodsInfo({
        shop_id: JSON.parse(this.shopInfo2021).shop_id,
        good_ids: list
          .map(g => {
            return g.id
          })
          .join(',')
      }).then(res => {
        this.goodsList = res.map(g => GoodsProxy.create(g))
      })
    } else this.goodsList = []
  }

  mounted() {
    console.log(this.realFloorList, 'elevator-add-floor')
    if (this.realFloorList[0]) {
      const list = JSON.parse(
        JSON.stringify(this.realFloorList[0].goodsList)
      ).map(g => GoodsProxy.create(g))
      this.getGoodsList(list)
    }
    // this.goodsList

    // this.goodsList.forEach(v => {
    //   if (!v.originalData) v.originalData = {revise_price: v.price}
    // })
    console.log(this.goodsList, 'elevator-add-floor')
  }
}
