
import { Component, Mixins } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { TemplateExtra } from '../module'
import { GoodsProxy } from '../../services/goods'
import { SwiperProxy } from '../../services/swiper'
import GoodsCard from '../$components/goods-card/goods-card.vue'
import Placeholder from './placeholder.vue'
import { getGoodsInfo } from '../../api'

@Component({
  name: 'template-5',
  components: {
    Swiper,
    SwiperSlide,
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = []
  swiperProxy: SwiperProxy = null!

  get goodsListTop10() {
    if (this.goodsList.length > 10) {
      return this.goodsList.slice(0, 10)
    } else {
      return this.goodsList
    }
  }

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    })
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || []
      const first = list[0]
      if (first?.block_type === 'GOODSLIST') {
        list = first.block_value || []
      }
      // const ids = list.map(g => {return g.goods_id}).join(',')
      // console.log(ids, 'ids')
      // this.goodsList = list.map(g => GoodsProxy.create(g))
      const shopInfo2021: any = sessionStorage.getItem('shopInfo2021')
      getGoodsInfo({
        shop_id: JSON.parse(shopInfo2021).shop_id,
        good_ids: list
          .map((g: any) => {
            return g.goods_id
          })
          .join(',')
      }).then(res => {
        // eslint-disable-next-line no-return-assign
        if (!res) return this.goodsList = []
        this.goodsList = res.map(g => GoodsProxy.create(g))
      })
    })
  }
}
